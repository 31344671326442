@import "~@/erp/styles/variables/variables.scss";
















































































































































.table-fixed {
  max-height: 400px !important;
  ::v-deep {
    th {
      height: 35% !important;
      max-height: 35px !important;
      line-height: 35px !important;
    }
    td {
      height: 35% !important;
      max-height: 35px !important;
      line-height: 35px !important;
    }
  }
}
.table-fixed-mini {
  max-height: 300px !important;
}
.f_s_12 {
  font-size: 12px;
}
